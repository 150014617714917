import $ from 'jquery';
import { redraw } from '../utils/ajax';

require('slick-carousel');

$(document).on('click', '.add-to-cart', function (e) {
  e.preventDefault();

  redraw($(this).attr('href'), {});

  return false;
});

$(document).ready(function () {

  $('.slider').slick({
    fade: true,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: false,
    speed: 1500,
    prevArrow: $('.slider-btns.prev-btn'),
    nextArrow: $('.slider-btns.next-btn')
  });

  $('.poi-card').click(function (e) {
    e.preventDefault();

    window.location = $(this).find('a').attr('href');

    return false;
  })

  $('[data-target]').click(function (e) {
    e.preventDefault();

    $(this).closest('.sorting').find('a').removeClass('active');
    $(this).addClass('active');

    const target = $(this).data('target');

    $(target).siblings('.row').hide();
    $(target).show();

    return false;
  });

  // initAktivityMapa($('#aktivityMapa'));
});